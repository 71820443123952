:root {
  --menu-color: #d9edff;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: var(--menu-color);
  overflow: hidden;
  /* border-bottom-right-radius: 20px; */
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

/* Responsive styles for the menu */
.menu {
  background-color: var(--menu-color);
  float: left;
  width: 100%; /* Make it full width initially */
  padding: 3vmax; /* Ajustar el padding en vmax */
  float: left;
}

/* Adjust the width for larger screens */
@media screen and (min-width: 768px) {
  .menu {
    width: 30%;
     padding: 10px; 
     padding-right: 1px;
  }
}

/* Adjust the width for even larger screens */
@media screen and (min-width: 1200px) {
  .menu {
    width: 20%;
  }
}